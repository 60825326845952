@import url('https://fonts.googleapis.com/css?family=Montserrat');

.login-page {
  font-family: Montserrat, Arial;
  font-size: 1.063rem;
  font-weight: 300;
  line-height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  overflow: hidden;
  height: 935px;

  background: #FFFFFF;
}

.rectangle-1 {
  height: 100%;
  background: linear-gradient(90deg, #4bb965 0%, #0b6e51 100%);
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 17px;
  display: flex;
}

.frame-2 {
  width: 568px;
  height: 666px;
  object-fit: cover;
}

.rectangle-2 {
  height: 55%;
  background-color: #fff;
  border-radius: 35px;
  flex-direction: column;
  flex-basis: 25%;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 22px;
  padding-bottom: 73px;
  padding-left: 50px;
  padding-right: 2px;
  display: flex;
}

.frame {
  width: 172px;
  height: 96px;
  object-fit: cover;
  margin-top: 1px;
  margin-bottom: 11px;
}

.username {
  width: 107px;
  height: 21px;
  left: 767px;
  top: 433px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
  /* or 22px */


  color: #000000;
}

.num-09-input-field-1-instance-1 {
  width: 80%;
  flex-basis: 8%;
}

.password {
  width: 107px;
  height: 20px;
  left: 300px;
  margin-top: 536px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 130%;
  /* or 22px */


  color: #000000;
}

.num-09-input-field-instance {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 386px;
  height: 47px;
  left: 767px;
  margin-top: 20px;

  background: rgba(0, 0, 0, 0.07);
  border-radius: 117px;
  border-color: transparent;
}


.num-09-input-field-instance:hover {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 366px;
  height: 48px;
  left: 300px;
  top: 573px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 104px;
  border-color: transparent;
}

.num-03-filled-button-instance-1 {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  margin-top: 20px;
  width: 186px;
  height: 43px;
  margin-left: 105px;
  color: white;
  background: #0B6E51;
  border-radius: 101px;
  border-color: transparent;
  ;


}

.fontlogin {
  height: 22px;

  font-family: Nunito, Arial;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  /* identical to box height, or 22px */
  margin-top: 15px;
  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame-1 {
  width: 568px;
  height: 644px;
  object-fit: cover;
}

.logo {
  width: 172px;
  height: 96px;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: 11px;
  margin-right: 40px;
}

/* responsive */


.rectangle-2-new {
  background-color: #fff;
  border-radius: 35px;
}


.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;


  background: linear-gradient(90deg, #4bb965 0%, #0b6e51 100%);
}

.Auth-form {
  width: 370px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 20px;
  background-color: white;
  z-index: 999;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-div {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.label-new {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
  font-family: 'Montserrat';
}

.logo-new {
  width: 130px;
  /* height: 96px; */
  object-fit: cover;
}


.num-09-input-field-instance-new {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 100%;
  height: 47px;

  background: rgba(0, 0, 0, 0.07);
  border-radius: 117px;
  border-color: transparent;
  font-family: 'Montserrat';
}


.num-09-input-field-instance-new:hover {
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 104px;
  border-color: transparent;
}


.num-03-filled-button-instance-1-new {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 43px;
  margin-bottom: 40px;
  color: white;
  background: #0B6E51;
  border-radius: 101px;
  border-color: transparent;
  
}


.num-03-filled-button-instance-1-new:hover {
  background: #00954e;
}

.frame-2-new {
  height: 50%;
  position: absolute;
  bottom:20px;
  left: 0px;
  object-fit: cover;
}

.frame-1-new {
  /* width: 100%; */
  height: 50%;
  position: absolute;
  bottom:20px;
  right: 0px;
  object-fit: cover;
}


.icon-div {
  position: relative;
}

.psw_child{
  position: absolute;
  right:14px;
  top: 42px;
}


/*  */

/* spinner  */

#overlay {
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  display: none;
  background: black;
  opacity: .4;
  z-index: 9999;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}