@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat");

Table thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
}

.table>tbody>tr>td {
    vertical-align: middle;
}

td {
    font-family: "Montserrat";
}

tr:first-child td:first-child {
    border-top-left-radius: 10px;
}

tr:first-child td:last-child {
    border-top-right-radius: 10px;
}

tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

tr:first-child td {
    border-top-style: solid;
}

tr td:first-child {
    border-left-style: solid;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    /*  padding: 10px;*/
    margin-top: -30px;
}

.grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
    width: 300px;
    height: 300px;
}




/**/
.listFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*    width: 120%;*/
    width: 140%;
    padding-left: 0;
}

.listFlex_B {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*    width: 100%;*/
    width: 80%;
    padding-left: 0;
    /*    margin-left: 22px;*/
}

.listFlex li {
    list-style: none;
    display: inline-block;
    text-align: center;
    flex-basis: 50%;
    margin: 0px;
}

.listFlexSub2 {
    vertical-align: top
}



/**/

/* .dropdown {
    transform-style: preserve-3d;
    transform: translate3d(0,0,10px) !important;
 }
 
 .dropdown-menu{
     height: auto !important;
     position: relative !important;
     transform: translate3d(0,0,10px) !important;
 } */

.dropdown button {
    /* background-color: "#EFEFEF"; */
    background-color: #fff !important;
    border-color: #888 !important;
    border-width: 1px;
    color: #000000;
    font-weight: 600;
    width: 74px;
    height: 32px;
    margin: auto;
    line-height: 1.25rem;
    padding: 0px;
}

.dropdown button span {
    font-size: 12px;
}


.dropdown-menu.show {
    background-color: #ffffff !important;
}

.dropdown-menu.min-width {
    min-width: 150px;
}

a.dropdown-item {
    font-size: 12px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #fff !important;
}


.dropdown-item.active,
.dropdown-item:active
a {
    color: #000 !important;
}

.dropdown-item-checked::after {
    content: "\f00c";
    font-family: "FontAwesome";
    margin-left: 10px;
}

.dropdown-toggle:active,
.dropdown-toggle {
    background: transparent !important;
    color: #000 !important;
}

.dropdown-toggle::after {
    display: none !important;
}

/**/
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /*  background: #fff;*/

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    /*  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;*/
}

#swiper .sw-text1 {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: 500;
    float: left;
    font-size: 22px;
    font-family: "Montserrat";
    color: white;
}

#swiper .sw-text2 {
    margin-top: -40px;
    margin-left: 40px;
    font-weight: 800;
    float: left;
    font-size: 142px;
    font-family: "Montserrat";
    color: #00bb64;
    margin-bottom: 0px;
}

#swiper .sw-text4 {
    font-family: "Montserrat";
    margin-top: -4px;
    color: white;
    margin-bottom: 12px;
    font-size: 14px;
}

#swiper .sw-img1 {
    margin-top: 60px;
    margin-right: 149px;
    margin-bottom: 0px;
}


#swiper .sw-img2 {
    margin-top: 60px;
    margin-right: -4px;
    margin-bottom: 0px;
    filter: invert(1299%) sepia(66%) saturate(22292%) hue-rotate(11888deg) brightness(200%) contrast(190%);
    transform: rotate(180deg) scaleX(-1);
}

#swiper .sw-text3 {
    margin-right: -4px;
    color: #ff3c3c;
}

#swiper .sw-text3B {
    margin-right: -4px;
    color: rgba(0, 206, 54, 255);
}

.external-buttons {
    overflow: visible;
}

.external-buttons .swiper-button-prev,
.external-buttons.swiper-container-rtl .swiper-button-next {
    left: -16px;
    right: auto;
}

.external-buttons .swiper-button-next,
.external-buttons.swiper-container-rtl .swiper-button-prev {
    right: -16px;
    left: auto;
}

.swiper-button-prev,
.swiper-button-next {
    top: 58%;
    width: 40px;
    height: 40px;
    background: #fff;
    /*  border: 1px solid gray;*/
    border-radius: 50%;
    color: blue;
    font-weight: 700;
    outline: 0;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 16px;
}

.swiper-button-prev:after {
    position: relative;
    left: -1px;
    font-style: normal;
}

.swiper-button-next:after {
    position: relative;
    left: 1px;
    font-style: normal;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
}

/**/
#swiper_dropdown .dropdown button {
    background: #0f473e !important;
    color: #fff !important;
    border-color: transparent;
    position: absolute;
    top: 82px;
    right: 28px;
    z-index: 99;
    padding-right: 20px;
}

#swiper_dropdown .dropdown-menu.show {
    background-color: #ffffff;
}

#swiper_dropdown a.dropdown-item {
    font-size: 16px;
}

#swiper_dropdown .dropdown-item.active,
#swiper_dropdown .dropdown-item:active {
    background-color: #fff !important;
}

#swiper_dropdown .dropdown-item.active,
#swiper_dropdown .dropdown-item:active,
a {
    /* color: #000 !important; */
}

#swiper_dropdown .dropdown-item-checked::after {
    content: "\f00c";
    font-family: "FontAwesome";
    float: right;
}

#swiper_dropdown .dropdown-toggle:active,
#swiper .dropdown-toggle {
    background: #0f473e !important;
    color: #fff !important;
    border-color: transparent;
}

#swiper_dropdown .dropdown-toggle::after {
    display: none !important;
}

/* width */
::-webkit-scrollbar {
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: #4bb965;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4bb965;
}

::-webkit-scrollbar-button {
    width: 50px;
    height: 50px;
}

/* mozila */
.scrollbar {
    overflow-y: scroll;
    scrollbar-color: #4bb965 #d9d9d9;
    scrollbar-width: thin;


}

:root {
    scrollbar-color: #4BB965 #d9d9d9;
    scrollbar-width: thin !important;
}

.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

/**/
.btn-cirlce1 {
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-weight: bold;
    border-radius: 50%;
    background: url('../../../assets/Group 5.png') no-repeat;
    text-align: center;
    cursor: pointer;
    border: black;
}

.btn-cirlce2 {
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-weight: bold;
    border-radius: 50%;
    background: url('../../../assets/Group 4.png') no-repeat;
    text-align: center;
    cursor: pointer;
    border: black;
}

.rounded-corners-users {
    height: 37px;
    line-height: 37px;
    width: 37px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: -8px;
    background: url('../../../assets/_maskGroup.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: '#0F473E';
    text-align: center;
    cursor: pointer;
    border: black;
}

.rounded-corners-photo {
    height: 37px;
    line-height: 37px;
    width: 37px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: -8px;
    /* background: url('../../../assets/_maskGroup.jpg'); */
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: '#0F473E';
    text-align: center;
    cursor: pointer;
    border: black;
}

.rounded-corners-photo-detail {
    height: 80px;
    line-height: 80px;
    width: 80px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: -8px;
    /* background: url('../../../assets/_maskGroup.jpg'); */
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: '#0F473E';
    text-align: center;
    cursor: pointer;
    border: black;
}



/**/

.borderless td,
.borderless th,
.borderless tr,
.borderless table {
    border: 0 !important;
    border-spacing: 0 !important;
}

.selection-row {
    outline: none;
    border: 2px solid #3e9c41;
    /* create a BIG glow */
    box-shadow: 0px 0px 10px #2f9c33;
    -moz-box-shadow: 0px 0px 10px #2f9c33;
    -webkit-box-shadow: 0px 0px 10px #2f9c33;
}


/**/
.pagination {
    float: left;
    height: 12px;
}


.paginationSectionButtonNext {
    position: absolute;
    left: 272px;

    height: 22px;
    line-height: 22px;
    width: 22px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    border: black;
    margin-left: 20px;
}

.paginationSectionButtonNext:after {
    content: '';
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 2px;
    margin-left: -1px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #0F473E;
    border-right: 2px solid #0F473E;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.paginationSectionButtonPrev {
    position: absolute;
    left: 20px;

    height: 22px;
    line-height: 22px;
    width: 22px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    border: black;
    margin-right: 20px;
}

.paginationSectionButtonPrev:after {
    content: '';
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 2px;
    margin-left: 1px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #0F473E;
    border-right: 2px solid #0F473E;
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.paginationSectionLink {
    color: white !important;
    text-decoration: none;
    padding: 10px;
    font-size: 12px;
}

.paginationSectionLinkActive {
    font-weight: bold;
    color: #C4E9CD !important;
    text-decoration: none;
    padding: 16px;
    font-size: 14px;
    text-shadow: 0px 0px 30px white, 0px 0px 30px white, 0px 0px 30px white, 0px 0px 10px #553300, 0px 0px 10px #553300;
}

.paginationSection {
    width: 310px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    -moz-border-radius: 20px;
    background-color: #0F473E !important;
    /*    margin-bottom: 10px;
    margin-top: 150px;*/
}


/**/



.btnCloseTable {
    position: absolute;
    top: 184px;
    height: 38px;
    line-height: 38px;
    width: 38px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    border: black;
    margin-left: 28px;
    left: -44px;
    z-index: 99;
    background-color: #0F473E;
}

.btnCloseTable:after {
    content: '';
    display: inline-block;
    margin-top: 6px;
    margin-left: -2px;
    width: 12px;
    height: 12px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
}

/**/

.readout {
    /*  white-space: break-spaces;
  word-break: break-all;*/
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

/*resp*/
.listFlex_poin {
    list-style: none;
}

.listFlex_poin_bar {
    list-style: none;
}


.pieChartLegendText {
    overflow: hidden;
    width: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 13px;
    text-align: left;
    word-break: break-word;
}

.swiperSw {
    height: 100px;
}

.pieChartTitle {
    font-size: 14px;
    font-weight: bold;
    margin-top: 0px;
    font-family: "Montserrat";
    margin-left: 8px;
    margin-top: 10px;
    /* margin:0px;
    padding:0px;     */
}

.redirectText {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
    font-family: "Montserrat";
    color: #000;
}

.redirectText:hover {
    color: #0f473e;
}


.doughnutChartLegendText1 {
    font-family: "Montserrat";
    font-size: 12px;
    text-align: left;
    margin-bottom: -2px;
}

.doughnutChartLegendText2 {
    font-family: "Montserrat";
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 28px;
    font-weight: bold;
}


.box {
    width: 100%;
    height: 20px;
    background: #fff;
}

.box_1 {
    position: relative;
    top: -20px;
    float: left;
    width: 10px;
    padding: 10px 15px;
    height: 60px;
    position: relative;
    background: #fff;
}

.box_1:before,
.box_1:after {
    content: '';
    position: absolute;
    left: 0;
    height: 20px;
    width: 99%;
    background: #EFEFEF;
}

.box_1:before {
    top: 0;
    border-radius: 0 0 0 110px;

}

.box_1:after {
    bottom: 0;
    border-radius: 110px 0 0 0;
}

.box_2 {
    position: relative;
    top: -20px;
    float: left;
    width: 10px;
    padding: 10px 15px;
    height: 60px;
    position: relative;
    background: #fff;
}

.box_2:before,
.box_2:after {
    content: '';
    position: absolute;
    left: 0;
    height: 20px;
    width: 99%;
    background: #EFEFEF;
}

.box_2:before {
    top: 0;
    border-radius: 0 0 110px 0;
}

.box_2:after {
    bottom: 0;
    border-radius: 0 110px 0 0;
}


.peopleSubColumn1Hide {
    height: 0px;
    width: 0%;
    animation: slide 2s;
}


.clr_child {
    position: absolute;
    right: 8px;
    top: 2px;
    z-index: 99;
}


.clr_img_btn {
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-weight: bold;
    border-radius: 50%;
    background: url('../../../assets/xmark2.png') no-repeat;
    text-align: center;
    cursor: pointer;
    border: black;

}

.clr_img_btn:hover {
    filter: invert(100%) sepia(92%) saturate(2292%) hue-rotate(1200deg) brightness(200%) contrast(190%);
}



@media (max-width: 767px) {


    #basic-navbar-nav1 {
        visibility: hidden;
        width: 0px;
    }

    .dashboard1_new {
        height: 103%;
        margin-bottom: 20px;
    }

    .dashboardColumn1 {
        margin-bottom: 50px;
    }

    .pieChartTitleLongText {
        font-size: 12px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 170%;
    }

    .pieChartTitle {
        font-size: 12px;
        font-weight: bold;
    }


    .grid-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 10px;
        margin-top: -30px;
    }

    .grid-item {
        text-align: center;
        width: 100%;
        /* width: 160px; */
        /* height: 224px; */
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .pieChartSubColumn2 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .doughnutChartColumn {
        display: flex;
        justify-content: center;
    }

    #rectangle-40-new {
        display: block;
        font-size: 9px;
        text-align: center;
        color: #000;
        margin-top: -40px;
        margin-left: 0%;
    }

    .listFlex_B {
        width: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px;
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChartLegend {
        width: 100%;
    }

    .redirectText {
        font-size: 11px;
    }

    .pieChartLegendText {

        font-size: 12px;
        margin-right: 0px;

        white-space: pre-wrap;
        overflow-wrap: break-word;

        max-width: 32ch;
    }

    .doughnutChart {
        height: 210px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart3 {
        margin-bottom: 10px;
        height: 200px;
    }

    .doughnutChartLegendText1 {
        font-size: 12px;
    }

    .doughnutChartLegendText2 {
        font-size: 12px;
    }


    .dashboard12-new {
        height: 383px;
    }

    .swiperSw {
        height: 80px;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 55px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        top: 142px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        top: 142px;
        right: auto;
    }

    .top10Column1 {
        margin-bottom: 40px;
    }

    .top10Column2 {
        height: 568px;
    }

    .top10SubColumn2 {
        height: 510px;
    }

    .top10Column3 {
        height: 260px;
    }

    .top10SubColumn3 {
        height: 200px;
    }

    .peopleColumn {
        height: 100%;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }


    .peopleSubColumn2 {
        width: 100%;
        top: 44px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 20px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 100px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 20px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        margin-bottom: 70px;
    }

    .departColumn2 {
        height: 549px;
    }

    .departmentSubColumn2 {
        height: 457px;
    }

    .main-footer {
        display: none;
    }

}

@media (min-width: 768px) and (max-width: 909px) {

    #sidebarCollapse {
        display: none;
    }

    .dashboardColumn1 {
        margin-bottom: 20px;
    }

    .dashboard1_new {
        margin-bottom: 20px;
    }

    .pieChartTitleLongText {
        font-size: 12px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 170%;
    }

    .pieChartTitle {
        font-size: 10px;
        font-weight: bold;
    }

    .grid-item {
        text-align: center;
        width: 164px;
        height: 224px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px;
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChartLegend {
        width: 100%;
    }

    .redirectText {
        font-size: 12px;
    }

    .pieChartLegendText {
        width: 67px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 210px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart3 {
        margin-bottom: 10px;
        height: 200px;
    }

    .doughnutChartLegendText1 {
        font-size: 12px;
    }

    .doughnutChartLegendText2 {
        font-size: 12px;
    }


    .dashboard12-new {
        height: 320px;
    }

    .swiperSw {
        height: 80px;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 55px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        top: 132px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        top: 132px;
        right: auto;
    }

    .top10Column1 {
        margin-bottom: 20px;
    }

    .top10Column2 {
        height: 568px;
    }

    .top10SubColumn2 {
        height: 510px;
    }

    .top10Column3 {
        height: 260px;
    }

    .top10SubColumn3 {
        height: 200px;
    }

    .peopleColumn {
        height: 100%;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 90vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 20px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 100px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 20px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        margin-bottom: 20px;
    }

    .departColumn2 {
        height: 549px;
    }

    .departmentSubColumn2 {
        height: 457px;
    }

}

@media (min-width: 910px) and (max-width: 1023.98px) {

    #sidebarCollapse {
        display: none;
    }

    .dashboardColumn1 {
        margin-bottom: 20px;
    }

    .dashboard1_new {
        margin-bottom: 20px;
    }

    .pieChartTitleLongText {
        font-size: 12px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 170%;
    }

    .pieChartTitle {
        font-size: 12px;
        font-weight: bold;
    }

    .grid-item {
        text-align: center;
        width: 200px;
        height: 224px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px;
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChartLegend {
        width: 100%;
    }

    .redirectText {
        font-size: 12px;
    }

    .pieChartLegendText {
        width: 80px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 210px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart3 {
        margin-bottom: 10px;
        height: 200px;
    }

    .doughnutChartLegendText1 {
        font-size: 12px;
    }

    .doughnutChartLegendText2 {
        font-size: 12px;
    }


    .dashboard12-new {
        height: 280px;
    }

    .swiperSw {
        height: 80px;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 55px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        top: 132px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        top: 132px;
        right: auto;
    }

    .top10Column1 {
        margin-bottom: 20px;
    }

    .top10Column2 {
        height: 568px;
    }

    .top10SubColumn2 {
        height: 510px;
    }

    .top10Column3 {
        height: 260px;
    }

    .top10SubColumn3 {
        height: 200px;
    }

    .peopleColumn {
        height: 434px;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 448px;
        width: 100%;
    }


    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 20px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 100px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 20px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        margin-bottom: 20px;
    }

    .departColumn2 {
        height: 549px;
    }

    .departmentSubColumn2 {
        height: 457px;
    }

}

@media (min-width: 1024px) and (max-width: 1146.98px) {

    #sidebarCollapse {
        display: none;
    }

    .dashboardColumn1 {
        margin-bottom: 20px;
    }

    .dashboard1_new {
        margin-bottom: 20px;
    }

    .pieChartTitleLongText {
        font-size: 12px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .pieChartTitle {
        font-size: 12px;
        font-weight: bold;
    }

    .grid-item {
        text-align: center;
        width: 230px;
        height: 224px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px;
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChartLegend {
        width: 100%;
    }

    .redirectText {
        font-size: 12px;
    }

    .pieChartLegendText {
        width: 100px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 210px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart3 {
        margin-bottom: 10px;
        height: 200px;
    }

    .doughnutChartLegendText1 {
        font-size: 12px;
    }

    .doughnutChartLegendText2 {
        font-size: 12px;
    }

    .swiperSw {
        height: 80px;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 55px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }

    .top10Column1 {
        margin-bottom: 20px;
    }

    .top10Column2 {
        height: 568px;
    }

    .top10SubColumn2 {
        height: 510px;
    }

    .top10Column3 {
        height: 260px;
    }

    .top10SubColumn3 {
        height: 200px;
    }

    .rounded-corners-users {
        height: 32px;
        line-height: 32px;
        width: 32px;
        background-position: 50% 20%;
        margin-right: -12px;
    }


    .btn-cirlce1 {
        height: 20px;
        line-height: 20px;
        width: 20px;
        background-position: 50% 50%;
    }

    .btn-cirlce2 {
        height: 20px;
        line-height: 20px;
        width: 20px;
        background-position: 50% 50%;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 20px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 100px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 20px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        margin-bottom: 20px;
    }

    .departColumn2 {
        height: 549px;
    }

    .departmentSubColumn2 {
        height: 457px;
    }


    .peopleColumn {
        height: 434px;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 448px;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

}


@media (min-width: 1147px) and (max-width: 1199.98px) {

    #sidebarCollapse {
        display: none;
    }

    .dashboard1_new {
        margin-bottom: 20px;
    }

    .dashboardColumn1 {
        margin-bottom: 20px;
    }

    .pieChartTitleLongText {
        font-size: 12px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .pieChartTitle {
        font-size: 12px;
        font-weight: bold;
    }

    .grid-item {
        text-align: center;
        width: 260px;
        height: 224px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px;
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChartLegend {
        width: 100%;
    }

    .redirectText {
        font-size: 12px;
    }

    .pieChartLegendText {
        width: 127px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 210px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 40px;
        margin-left: -20px;
    }

    .barChart3 {
        margin-bottom: 10px;
        height: 200px;
    }

    .doughnutChartLegendText1 {
        font-size: 12px;
    }

    .doughnutChartLegendText2 {
        font-size: 12px;
    }

    .swiperSw {
        height: 80px;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 55px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
        width: 20px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }

    .top10Column1 {
        margin-bottom: 20px;
    }

    .top10Column2 {
        height: 568px;
    }

    .top10SubColumn2 {
        height: 510px;
    }

    .top10Column3 {
        height: 260px;
    }

    .top10SubColumn3 {
        height: 200px;
    }

    .rounded-corners-users {
        height: 32px;
        line-height: 32px;
        width: 32px;
        background-position: 50% 20%;
        margin-right: -12px;
    }


    .btn-cirlce1 {
        height: 20px;
        line-height: 20px;
        width: 20px;
        background-position: 50% 50%;
    }

    .btn-cirlce2 {
        height: 20px;
        line-height: 20px;
        width: 20px;
        background-position: 50% 50%;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 20px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 100px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 20px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        margin-bottom: 20px;
    }

    .departColumn2 {
        height: 549px;
    }

    .departmentSubColumn2 {
        height: 457px;
    }


    .peopleColumn {
        height: 434px;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 448px;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }


}


/* @media (min-width: 1200px) and (max-width: 1287.98px) and (max-height: 800px)  {

    #sidebarCollapse{
        display: none;
    }

    .dashboardColumn1 {
        margin-bottom: 0px;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .pieChartTitle{
        font-size: 12px;
    }

    .grid-item {
        text-align: center;
        width: 210px;
        height: 234px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .barChartLegend {
        width: 90px;
    }

    .redirectText {
        font-size: 10px;
    }

    .pieChartLegendText {
        width: 50px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 240px;
    }

    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 40px
    }

    .barChart3 {
        margin-bottom: 10px;
        height: 232px;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 64px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }


    .top10Column2 {
        height: 590px;
    }

    .top10SubColumn2 {
        height: 510px;
    }

    .top10Column3 {
        height: 282px;
    }

    .top10SubColumn3 {
        height: 220px;
    }

    .rounded-corners-users{
        display: none;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn2 {
        height: 590px;
    }

    .departmentSubColumn2 {
        height: 480px;
    }

    .peopleColumn {
        height: 474px;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 488px;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .main-footer {
        display: none;
    }


} */

/* @media (min-width: 1200px) and (max-width: 1287.98px) and (min-height: 801px) */

@media (min-width: 1200px) and (max-width: 1287.98px) {

    #sidebarCollapse {
        display: none;
    }

    .dashboardColumn1 {
        margin-bottom: 0px;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .pieChartTitle {
        font-size: 12px;
        font-weight: bold;
    }


    .grid-item {
        text-align: center;
        width: 210px;
        height: 234px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .barChartLegend {
        width: 90px;
    }

    .redirectText {
        font-size: 10px;
    }

    .pieChartLegendText {
        width: 50px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 240px;
    }

    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 6vh;
    }

    .barChart3 {
        height: 32vh;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 64px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }


    .top10Column1 {
        height: 89vh;
    }

    .top10Column2 {
        height: 89vh;
    }

    .top10SubColumn2 {
        height: 76vh;
    }

    .top10SubColumn3 {
        height: 32vh;
    }

    .rounded-corners-users {
        display: none;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 89vh;
    }

    .departColumn2 {
        height: 89vh;
    }

    .departmentSubColumn2 {
        height: 72vh;
    }

    .peopleColumn {
        height: 68vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .main-footer {
        display: none;
    }


}


@media (min-width: 1288px) and (max-width: 1351.98px) {

    #sidebarCollapse {
        display: none;
    }

    .dashboardColumn1 {
        margin-bottom: 0px;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .grid-item {
        text-align: center;
        width: 210px;
        height: 234px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .barChartLegend {
        width: 90px;
    }

    .redirectText {
        font-size: 10px;
    }

    .pieChartLegendText {
        width: 80px;
        font-size: 11px;
    }

    .doughnutChart {
        height: 240px;
    }

    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 6vh;
    }

    .barChart3 {
        height: 32vh;
    }



    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 64px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }


    .top10Column1 {
        height: 89vh;
    }

    .top10Column2 {
        height: 89vh;
    }

    .top10SubColumn2 {
        height: 79vh;
    }

    .top10SubColumn3 {
        height: 30vh;
    }

    .rounded-corners-users {
        display: none;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 89vh;
    }

    .departColumn2 {
        height: 89vh;
    }

    .departmentSubColumn2 {
        height: 72vh;
    }

    .peopleColumn {
        height: 68vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .main-footer {
        display: none;
    }


}

/*1360 & 1366*/
@media (min-width: 1352px) and (max-width: 1439.98px) {

    #sidebarCollapse {
        display: none;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .grid-item {
        text-align: center;
        width: 210px;
        height: 234px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }

    .redirectText {
        font-size: 11px;
    }

    .barChartLegend {
        width: 100px;
    }

    .pieChartLegendText {
        width: 90px;
        font-size: 13px;
    }

    .doughnutChart {
        height: 210px;
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }


    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 6vh;
    }

    .barChart3 {
        height: 32vh;
    }

    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -20px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 72px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }

    .top10Column1 {
        height: 89vh;
    }

    .top10Column2 {
        height: 89vh;
    }

    .top10SubColumn2 {
        height: 75vh;
    }

    .top10SubColumn3 {
        height: 26vh;
    }


    .rounded-corners-users {
        display: none;
    }

    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 89vh;
    }

    .departColumn2 {
        height: 89vh;
    }

    .departmentSubColumn2 {
        height: 72vh;
    }

    .peopleColumn {
        height: 68vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

    .main-footer {
        display: none;
    }

}


@media (min-width: 1440px) and (max-width: 1599.98px) {


    #sidebarCollapse {
        display: none;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .grid-item {
        text-align: center;
        width: 210px;
        height: 234px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChartLegend {
        width: 120px;
    }

    .pieChartLegendText {
        width: 110px;
        font-size: 13px;
    }

    .doughnutChart {
        height: 210px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 200px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 6vh;
    }

    .barChart3 {
        height: 32vh;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -30px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 82px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }


    .top10Column1 {
        height: 91vh;
    }

    .top10Column2 {
        height: 91vh;
    }

    .top10SubColumn2 {
        height: 81vh;
    }

    .top10SubColumn3 {
        height: 40vh;
    }

    .rounded-corners-users {
        display: none;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 91vh;
    }

    .departColumn2 {
        height: 91vh;
    }

    .departmentSubColumn2 {
        height: 81vh;
    }

    .peopleColumn {
        height: 58vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

}

@media (min-width: 1600px) and (max-width: 1679.90px) {

    #sidebarCollapse {
        display: none;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .grid-item {
        text-align: center;
        width: 260px;
        height: 244px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChartLegend {
        width: 140px;
    }

    .pieChartLegendText {
        width: 110px;
        font-size: 13px;
    }

    .doughnutChart {
        height: 220px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 220px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 220px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 6vh;
    }

    .barChart3 {
        height: 32vh;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -30px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 82px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }

    .top10Column1 {
        height: 91vh;
    }

    .top10Column2 {
        height: 91vh;
    }

    .top10SubColumn2 {
        height: 81vh;
    }

    .top10SubColumn3 {
        height: 40vh;
    }



    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 91vh;
    }

    .departColumn2 {
        height: 91vh;
    }

    .departmentSubColumn2 {
        height: 81vh;
    }

    .peopleColumn {
        height: 58vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

}


@media (min-width: 1680px) and (max-width: 1919.90px) {

    #sidebarCollapse {
        display: none;
    }

    .pieChartTitleLongText {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .grid-item {
        text-align: center;
        width: 280px;
        height: 254px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 40px
    }

    .barChartLegend {
        width: 150px;
    }

    .pieChartLegendText {
        width: 110px;
        font-size: 13px;
    }

    .doughnutChart {
        height: 240px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 240px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 40px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 240px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 8vh;
    }

    .barChart3 {
        height: 34vh;
    }


    #swiper .sw-text1 {
        margin-top: 10px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
    }

    #swiper .sw-text2 {
        margin-top: -30px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 82px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 10px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 10px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }

    .top10Column1 {
        height: 91vh;
    }

    .top10Column2 {
        height: 91vh;
    }

    .top10SubColumn2 {
        height: 83vh;
    }

    .top10SubColumn3 {
        height: 43vh;
    }


    .titleText {
        font-size: 12px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 100px;
        width: 140px;
        font-size: 12px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 91vh;
    }

    .departColumn2 {
        height: 91vh;
    }

    .departmentSubColumn2 {
        height: 83vh;
    }

    .peopleColumn {
        height: 64vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

}


@media (min-width: 1920px) and (max-width: 2265.98px) {

    #sidebarCollapse {
        display: none;
    }

    .pieChartTitleLongText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
        width: 140%;
    }

    .pieChartTitle {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0px;
        font-family: "Montserrat";
    }

    .grid-item {
        text-align: center;
        width: 310px;
        height: 284px;
        margin-left: -20px;
    }


    .listFlex_B {
        width: 120%;
        padding-left: 0;
    }

    .listFlex_poin {
        list-style: none;
        height: 44px
    }


    .listFlex_poin_bar {
        list-style: none;
        height: 48px
    }

    .barChartLegend {
        width: 190px;
    }

    .redirectText {
        font-size: 16px;
    }

    .pieChartLegendText {
        width: 130px;
        font-size: 16px;
    }

    .doughnutChart {
        height: 240px;
    }

    .barChart {
        margin-bottom: 10px;
        height: 240px;
    }

    .listFlex_poin_bar2 {
        list-style: none;
        height: 48px
    }

    .barChart2 {
        margin-bottom: 10px;
        height: 240px;
    }

    .listFlex_poin_bar3 {
        list-style: none;
        height: 10vh;
    }

    .barChart3 {
        height: 36vh;
    }


    .doughnutChartLegendText1 {
        font-size: 16px;
    }

    .doughnutChartLegendText2 {
        font-size: 16px;
    }


    #rectangle-36-new-btn {
        font-size: 16px;

    }

    #rectangle-37-new-btn {
        font-size: 16px;

    }

    #rectangle-38-new-btn {
        font-size: 16px;

    }

    .swiperSw {
        height: 120px;
    }

    .dropdown button span {
        font-size: 16px;
    }

    .dropdown button {
        width: 94px;
        height: 42px;
    }

    #swiper .sw-text1 {
        margin-top: 20px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 18px;
    }

    #swiper .sw-text2 {
        margin-top: -30px;
        margin-left: 10px;
        font-weight: 800;
        font-size: 112px;
        font-family: "Montserrat";
    }

    #swiper .sw-img1 {
        margin-top: 20px;
        margin-right: -4px;
    }


    #swiper .sw-img2 {
        margin-top: 20px;
        margin-right: -4px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 30px;
        left: auto;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 30px;
        right: auto;
    }

    .top10Column1 {
        height: 91vh;
    }

    .top10Column2 {
        height: 91vh;
    }

    .top10SubColumn2 {
        height: 85vh;
    }

    .top10SubColumn3 {
        height: 45vh;
    }


    .titleText {
        font-size: 14px;
        align-items: center;
        color: #000000;
        margin-top: 18px;
        margin-left: 50px;
    }

    .titleSpan {
        position: absolute;
        top: 6px;
        left: 120px;
        width: 140px;
        font-size: 14px;
        margin-top: 32px;
        margin-left: 50px;
        max-height: 3.6em;
        line-height: 1.8em;
    }

    .departColumn1 {
        height: 91vh;
    }

    .departColumn2 {
        height: 91vh;
    }

    .departmentSubColumn2 {
        height: 85vh;
    }

    .peopleColumn {
        height: 64vh;
    }

    .paginationSection {
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .peopleSubColumn1 {
        height: 80vh;
        width: 100%;
    }

    .peopleSubColumn2 {
        width: 100%;
        top: 34px;
        margin-top: 20px;
    }

    .peopleSubColumn2Textheader1 {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: bold;
        padding: 4px;
    }

}


/*@media all and (display-mode: fullscreen) {
  .content {
    padding: 0px;
  }
}*/