@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat");

body {
    overflow: hidden;
    background-color: white;
    color: black;
}

.title {
    background-color: #efefef;
}

.rectangle-3 {
    position: absolute;
    width: 199px;
    height: 1080px;
    left: -6px;
    top: 0px;

    background: #4BB965;
    border-radius: 0px 40px 50px 0px;
}


.rectangle-37 {
    position: absolute;
    width: 332px;
    height: 52px;
    left: 230px;
    top: 39px;

    background: #4BB965;
    border-radius: 50px;
}


.friday-2-february-20 {
    position: absolute;
    width: 299px;
    height: 33px;
    left: 250px;
    top: 49px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    /* or 31px */

    /* display: flex; */
    align-items: center;

    color: #F7F9F8;
}

.rectangle-38 {
    position: absolute;
    width: 151px;
    height: 52px;
    left: 580px;
    top: 39px;

    background: #0F473E;
    border-radius: 50px;
}

.num-1226 {
    position: absolute;
    width: 103px;
    height: 33px;
    left: 604px;
    top: 49px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 130%;
    /* or 45px */

    display: flex;
    align-items: center;
    letter-spacing: 0.12em;

    color: #4AB865;
}

.message-icon {
    position: absolute;
    width: 31px;
    height: 25px;
    left: 1613px;
    top: 50px;

}

.notification-icon {
    position: absolute;
    width: 27px;
    height: 33px;
    left: 1662px;
    top: 44px;
}

.ellipse-1 {
    position: absolute;
    width: 56px;
    height: 56px;
    left: 1707px;
    top: 33px;
    background: #D9D9D9;
    border-radius: 30px;
}


.jed-creative {
    position: absolute;
    width: 112px;
    height: 22px;
    left: 1773px;
    top: 49px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 130%;
    /* or 16px */

    display: flex;
    align-items: center;

    color: #323232;
}

.vector-1 {
    position: absolute;
    width: 14px;
    height: 7px;
    left: 1865px;
    top: 57px;
    cursor: pointer;
}


.dashboard1 {
    position: absolute;
    width: 1048px;
    height: 505px;
    left: 230px;
    top: 127px;

    background: #EFEFEF;
    border-radius: 20px;
}

.dashboard1B {
    position: absolute;
    width: 1048px;
    height: 606px;
    left: 230px;
    top: 510px;

    background: #EFEFEF;
    border-radius: 20px;
}



.dashboard2 {
    position: absolute;
    width: 336px;
    height: 23px;
    left: 50px;
    top: 50px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 130%;
    /* or 25px */

    display: flex;
    align-items: center;

    color: #000000;

}

.dashboard3 {
    position: absolute;
    width: 114px;
    height: 23px;
    left: 900px;
    top: 50px;

    font-family: 'Montserrat';
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    display: flex;
    align-items: center;

    color: #1A8E6C;


}

.people1 {
    position: absolute;
    width: 1660px;
    height: 988px;
    left: 230px;
    top: 127px;
    background: #EFEFEF;
    border-radius: 20px;
}

.people {
    width: 30px;
    height: 40px;
}


.mask-group {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 40px;
    top: 20px;
}

.maskgroupround {
    width: 50px;
    height: 50px;
    margin-left: 35px;
    margin-top: 9px;
    top: 50px;
    background: #0B6E51;
    border-radius: 25px;
}


.dashboard4 {
    position: absolute;
    width: 969px;
    height: 77px;
    left: 40px;
    top: 100px;
    background: #C4E9CD;
    border-radius: 10px;
}


.dashboard5 {
    position: absolute;
    width: 114px;
    height: 23px;
    left: 100px;
    top: 25px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #000000;
}

.dashboard6 {
    position: absolute;
    width: 114px;
    height: 23px;
    left: 250px;
    top: 25px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #000000;
}

.dashboard7 {
    position: absolute;
    width: 114px;
    height: 23px;
    left: 400px;
    top: 25px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #000000;

}

.dashboard8 {
    position: absolute;
    width: 114px;
    height: 23px;
    left: 500px;
    top: 25px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #000000;
}

.dashboard9 {
    position: absolute;
    width: 114px;
    height: 23px;
    left: 700px;
    top: 25px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    display: flex;
    align-items: center;

    color: #000000;
}

.dashboard10 {
    margin-left: 850px;
    margin-top: 15px
}



.dashboard11 {
    position: absolute;
    width: 969px;
    height: 77px;
    left: 40px;
    top: 175px;
    background: rgba(255, 255, 255, 0.56);
    border-radius: 10px;
}

.dashboard12 {
    position: absolute;
    width: 583px;
    height: 407px;
    left: 1310px;
    top: 127px;
    background: #EFEFEF;
    border-radius: 20px;
}


.dashboard13 {
    position: absolute;
    width: 336px;
    height: 23px;
    left: 50px;
    top: 40px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 130%;
    /* or 25px */

    display: flex;
    align-items: center;

    color: #000000;

}


.dashboard14 {
    position: absolute;
    width: 336px;
    height: 23px;
    left: 450px;
    top: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 25px */
    display: flex;
    align-items: center;
    color: #000000;

}

.grafik1 {
    position: absolute;
    width: 513px;
    height: 300px;
    top: 80px;
    margin-left: 35px;
    /*    background: #0F473E;*/
    border-radius: 20px;
}

.dashboard15 {
    position: absolute;
    width: 166px;
    height: 37px;
    left: 30px;
    top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    /* or 31px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.dashboard16 {
    position: absolute;
    width: 59px;
    height: 33px;
    left: 400px;
    top: 20px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.dashboard17 {
    position: absolute;
    width: 12px;
    height: 5px;
    left: 450px;
    top: 33px;
}

.dashboard18 {
    position: absolute;
    width: 229px;
    height: 192px;
    left: 50px;
    top: 50px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 150px;
    line-height: 130%;
    /* or 195px */

    display: flex;
    align-items: center;

    color: #4AB865;

}

.dashboard19 {
    margin-top: 120px;
    margin-left: -20px;

}

.dashboard20 {
    margin-top: -60px;
    margin-left: 490px;
}


.dashboard21 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 169.5%;
    /* or 27px */
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-top: 40px;
    margin-left: 25px;
}

.dashboard22 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    position: absolute;
    width: 66.15px;
    height: 38px;
    left: 300px;
    top: 170px;

}

.dashboard23 {
    position: absolute;
    width: 66.15px;
    height: 38px;
    left: 330px;
    top: 200px;
    color: #FF4D4D;

}

.dashboard24 {
    width: 150px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 169.5%;
    /* or 27px */
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-left: 300px;
    margin-top: -30px;
}

.dashboard25 {
    width: 150px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 169.5%;
    /* or 27px */
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-left: 300px;
    margin-top: -20px;
}

.dashboard26 {
    position: absolute;
    width: 1048px;
    height: 458px;
    left: 230px;
    top: 657px;

    background: #EFEFEF;
    border-radius: 20px;
}

.dashboard26B {
    position: absolute;
    width: 1048px;
    height: 351px;
    left: 230px;
    top: 130px;

    background: #EFEFEF;
    border-radius: 20px;
}

.dashboard27 {
    width: 800px;
    height: 23px;
    left: 266px;
    top: 685px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 130%;
    /* or 25px */

    display: flex;
    align-items: center;

    color: #000000;
    margin-top: 50px;
    margin-left: 50px;
}


.dashboard28 {
    position: absolute;
    width: 573px;
    height: 548px;
    left: 1310px;
    top: 566px;
    background: #EFEFEF;
    border-radius: 20px;
}


/*new*/

#rectangle-36-new {

    position: absolute;
    width: 151px;
    height: 52px;
    left: 240px;
    top: 49px;
    background: #0F473E;
    border-radius: 50px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    color: #F7F9F8;
}

#rectangle-36-new span {
    color: white;

}

#rectangle-37-new {
    position: absolute;
    width: 299px;
    height: 33px;
    left: 410px;
    top: 49px;
    border-radius: 25px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    color: #F7F9F8;
    background: #4BB965;

}

#rectangle-37-new span {
    color: white;

}

#rectangle-38-new {
    position: absolute;
    width: 151px;
    height: 52px;
    left: 730px;
    top: 49px;
    background: #0F473E;
    border-radius: 50px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    display: flex;
    color: #F7F9F8;

}

#rectangle-38-new span {
    color: #4BB965;

}

#rectangle-39-new {
    position: absolute;
    width: 168px;
    height: 31px;
    right: 842px;
    top: 18px;
    background: #dddad6;
    border-radius: 0px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px;
    font-weight: 600;
    font-size: 25px;
    line-height: 130%;
    display: flex;
    color: #000;

}

#rectangle-39-new span {
    color: #4BB965;
}

#rectangle-40-new {
    /*    position: absolute;
    width: 420px;
    height: 31px;
    right: 499px;
    top: 18px;*/
    font-weight: bold;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    display: flex;
    color: #000;
    cursor: pointer;
    font-family: 'Montserrat';
    margin-top: -30px;
    margin-left: 50%;

}

#rectangle-40-new span {
    font-weight: bold;
    font-family: 'Montserrat';
    margin-left: 10px;
    font-size: 12px;
}

#rectangle-41-new {
    position: absolute;
    width: 420px;
    height: 31px;
    right: 455px;
    top: 38px;
    border-radius: 0px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px;
    font-weight: 300;
    font-size: 25px;
    line-height: 130%;
    display: flex;
    color: #000;
    cursor: pointer;
    font-family: 'Montserrat';

}

#rectangle-41-new span {
    font-weight: bold;
    font-family: 'Montserrat';
    margin-left: 10px;
}

.profilemenu {
    position: absolute;
    width: 100px;
    height: 407px;
    left: 1810px;
    top: 70px;
    background: #EFEFEF;
    border-radius: 20px;
    border-color: black;

}

.liprofile {
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #323232;
}

.titleText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    align-items: center;
    color: #000000;
    margin-top: 32px;
    margin-left: 50px;
}

.titleSpan {
    position: absolute;
    top: 26px;
    left: 150px;
    width: 238px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-size: 19px;
    align-items: center;
    color: #000000;
    margin-top: 32px;
    margin-left: 50px;

    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
}

.dropdown-toggle {
    white-space: nowrap;
}


.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #7E7E7E;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212529;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/*responsive*/

#rectangle-36-new-btn {
    background: #0F473E;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    color: #F7F9F8;
}

#rectangle-36-new-btn span {
    color: white;

}

#rectangle-37-new-btn {
    background: #4BB965;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    color: #F7F9F8;
}

#rectangle-37-new-btn span {
    color: white;

}

#rectangle-38-new-btn {
    background: #0F473E;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    color: #F7F9F8;
}

#rectangle-38-new-btn span {
    color: #4BB965;
}


.multiLineLabel {
    display: inline-block;
    box-sizing: border-box;
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 10px;
    width: 100px;
}

.multiLineLabel .textMaxLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.dashboard1_new {
    height: 100%;
    background: #EFEFEF;
    border-radius: 20px;
}

.dashboard1Child_new {
    height: 100%;
    background: #EFEFEF;
    border-radius: 20px;
}

.dashboard12-new {
    height: 100%;
    background: #efefef;
    border-radius: 20px;
}

#rectangle-39-new-rsp {
    float: left;
    width: 122px;
    height: 18px;
    background: #dddad6;
    border-radius: 0px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    color: #000;
    margin-left: 20px;
    margin-top: 10px;

}

#rectangle-39-new-rsp span {
    color: #4BB965;

}

.grafik1-new {
    top: 80px;
    border-radius: 20px;
    padding-left: 40px;
    padding-right: 40px;
}


.dashboard13-new {
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 19px;
    line-height: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    float: left;
    margin-top: 12px;
    margin-left: 40px;
}

.react-bootstrap-table table {
    table-layout: auto;
}

/* print */

.print-div{
    /* overflow: auto;
    max-height: 70vh; */
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@media print {
    .print-only {
        display: block;

        /* width: 210mm;
        height: 296mm; */
        /* padding: 0;
        margin: 0; */
        background: white;

    }
}

@page {
    size: A4 landscape;
    /* size: auto; */
    /* size: landscape; */
    /* size: 8in 5in; */
    /* margin: 4mm; */
}


@media screen {
    /* .hide-print {
        visibility: hidden;
        display: none;
    } */
}

/* print */


@media (max-width: 1023px) {

    body {
        overflow: visible;
    }

    .dashboard12-new {
        height: 240px;
    }

    .dashboard13-new {
        font-size: 10px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 10px;
        width: 100px;
        height: 10px;
    }
}

@media (min-width: 1024px) and (max-width: 1146.98px) {

    body {
        overflow: visible;
    }

    .dashboard12-new {
        height: 240px;
    }

    .dashboard13-new {
        font-size: 10px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 12px;
        width: 100px;
        height: 12px;
    }
}

@media (min-width: 1147px) and (max-width: 1199.98px) {

    body {
        overflow: visible;
    }

    .dashboard12-new {
        height: 240px;
    }

    .dashboard13-new {
        font-size: 10px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 12px;
        width: 100px;
        height: 12px;
    }
}

@media (min-width: 1200px) and (max-width: 1359.98px) {

    .dashboard12-new {
        height: 270px;
    }

    .dashboard13-new {
        font-size: 14px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 14px;
        width: 100px;
        height: 14px;
    }

}

@media (min-width: 1360px) and (max-width: 1439.98px) {

    .dashboard13-new {
        font-size: 14px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 14px;
    }

}


@media (min-width: 1440px) and (max-width: 1599.98px) {

    .dashboard13-new {
        font-size: 14px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 14px;
    }

}

@media (min-width: 1600px) and (max-width: 1679.90px) {

    .dashboard13-new {
        font-size: 14px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 14px;
    }

}

@media (min-width: 1680px) and (max-width: 1919.90px) {

    .dashboard13-new {
        font-size: 14px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 14px;
    }

}

@media (min-width: 1920px) and (max-width: 2265.98px) {

    .dashboard13-new {
        font-size: 18px;
        font-weight: bold;
    }

    #rectangle-39-new-rsp {
        font-size: 18px;
    }

    #rectangle-40-new {
        font-size: 14px;
    }

    #rectangle-40-new span {
        font-size: 14px;
    }

}