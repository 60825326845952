.main-content.horizontal-content {
  // margin-top: 64px;
  margin-top: 84px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.panel-body {
  padding: 0;
  border-color: white;
}

table.table tr th {
  background-color: #0f7051;
  color: white;
  padding: 27px;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #c5e9cd;
  color: black;
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #ffffff;
  color: black;
}

th:first-of-type {
  border-top-left-radius: 10px;
}
th:last-of-type {
  border-top-right-radius: 10px;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

div.rounded-corners {     
  height: 100%;
  width: 100%; 
  border-radius: 50%;
  overflow: hidden;
  background-color: #0f7051;
}

.page-link {
  width: fit-content;
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.container{
    // padding: 10%;
    text-align: center;
}
#custom-search-input {
    margin:0; 
    margin-top: 10px;
    padding: 0;
 
    
}
 
#custom-search-input .search-query {
    width:100%;
    padding-right: 3px;
    padding-left: 15px;
        /* IE7-8 doesn't have border-radius, so don't indent the padding */
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 50px;
    
}
 
#custom-search-input button {
    border: 0;
    background: none;
    /** belows styles are working good */
    padding: 2px 5px;
    margin-top: 2px;
    position: absolute;
    left: 4px;
    /* IE7-8 doesn't have border-radius, so don't indent the padding */
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color:#313131;
    cursor: unset;
}
 
.search-query:focus{
    z-index: 0;   
}



.goodkesimpulan{
position: absolute;
width: 85%;
margin-top:158px;
margin-left:244px;
font-family: 'Montserrat';

  // font-style: normal;
  // font-weight: 700;
  // font-size: 20px;
  // color: #000000;
  // line-height: 140%;
/* or 28px */

}

.flex{
  display: flex;
}
.bg-blue{
  background: blue;
}
.bg-blue{
  background: red;
}
.bg-DarkGreen{
  background: #0F473E;
}
.bg-green{
  background: #4BB965;
}

.color-red{
  color: #FF4D4D;
}
.color-black{
  color: #000000;
}
.color-green{
  color: #4BB965;
}
.color-DarkGreen{
  color:#0F473E
}
.underline{
  text-decoration: underline;
}
.rounded-pagination{
  padding: 3px 8px;
}
.rounded-pagination-msg{
  padding: 5px 12px;
}
.max-width{
  max-width: 370px;
}
.font-20{
font-size: 20px;
}
.p-conc{
  padding: 1rem
}
.w-50{
width: 50%;
}
.max-width-new{
  width: 80vw;
}
.bottom-0{
  position: absolute;
  bottom: 10px;
  margin-bottom: 10px;
  right: 0;
}
.concTitle{
  font-family: 'Montserrat';
  font-weight: 900 !important;
  font-size: 20px;
  line-height: 28px;
}
.width-conc{
  width: 50%;
}
.concDesc{
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
}
.display-absolute{
  position: absolute;
}
.display-relative{
  position: relative;
}

.concPersen{
  font-family: Montserrat;
font-size: 24px;
border: 3px solid #0F473E;
// padding: 33px 20px;
width: 130px;
height: 130px;
line-height: 60px;
border-radius: 100%;
line-height: 120px;
letter-spacing: 0.12em;
text-align: center;
}

.concPersenYear{
  font-family: Montserrat;
  background:   #4BB965;
padding: 15px 10px;
font-size: 12px;
top: 2px;
color: #ffffff;
}
.h-screen{
  // height: ;
  min-height: 80vh;
  max-height: 100vh;
}

.conclusion{
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  color: #000000;
  margin-top: 50px;
  width:50%;
}

.modal {
  width: 1279px;
  z-index: 9999;
}


.table-head-fixed {
  position: sticky;
  top: 0;
  z-index: 6;
  width: 100%;
  text-align: start;
  background-color: #EFEFEF !important;
  color: #000000 !important;
}

.table-custom-width {
  width: 500px;
  vertical-align: middle;

}

.bg-darkGreen {
  background-color: #0f7051;
  height: 125px !important;
  padding-top: 15px;
  padding-left: 25px;
}
.row-custom-selected {
  border-bottom: 3px solid rgba(75, 185, 101, 1);
  // background: rgba(75, 185, 101, 1);
}
#slide {
  position: absolute;
  // left: -50px;
  width: 100px;
  height: 100px;
  // background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  // animation-name: slidein;
}
search-custom {
  width: 400px;
}
@-webkit-keyframes slide {
  -100% { left: 0; }
}

@keyframes slide {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}