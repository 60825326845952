@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css?family=Montserrat');

body{
font-family: 'Montserrat';
color : "black";
}

body .title{
font-family: 'Montserrat';
color : "black";
}

li.active > a {
/*   border-right:5px solid #00c065;*/
   left: 0;
}

.sidebar-list {
list-style-type: none;
/*margin-bottom: 10px;*/
margin-left:0px;
padding-left:0px;
display: inline-block;
width: 150px;
}

.sidebar-list:hover .dashboard-text {
   color:#C4E9CD !important;
}

.sidebar-list:hover .dashboard-icon {
   filter: invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%);
}


.sidebar-list:hover .peopletext {
   color:#C4E9CD !important;
}

.sidebar-list:hover .people {
   filter: invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(99%) contrast(26%);
}

.sidebar-list img
{
float:left;
}

.sidebar-list p,.sidebar-list span {
text-align: left; 
display:inline-block;
padding: 0;
font-size:18px;
font-family: 'Montserrat';
color: white;
margin-top: 4px;
margin-left: 8px;
width: 124px;
}

.sidebar-list .garisbawahactive
{
margin-left: 22px;
}

.garisbawahactive_p {
    margin-left: -29px;
    margin-top: 10px;
}
