.main-header-left {
    .header-brand {
        .logo-white {
            position: relative;
            display: inline-flex;
            vertical-align: middle;
            height: 4rem;
        }
        .text-brand {
            position: relative;
            margin-left: 0.6rem !important;
            color: white;
            display: inline-flex;
            vertical-align: middle;
            font-size: 20px;
        }
    }
    .main-header-center {
        .nav {
            .active {
                border-bottom: solid;
                border-width: 3px;
            }
        }
    }
}

.main-header-right {
    .main-header-profile {
        padding: 0.5rem;
        margin: 0;
        color: white;
    }
    .main-header-message {
        position: relative;
        margin: auto 0;

        .nav-link {
            display: block;
            font-size: 20px;
            color: #5e597d;
            position: relative;
            line-height: 0;
            outline: none;
            padding: 0.5rem;
            border: 1px solid rgba(255, 255, 255, 0.2);
            margin: 0 7px;
            border-radius: 50%;
            height: 38px;
            width: 38px;
            text-align: center;
            cursor: pointer;

            .icon {
                line-height: 2rem;
                text-align: left;
                font-size: 19px;
                color: white;
            }
            
        }

        .dropdown-menu {
            display: block;
            top: 49px;
            border-radius: 0;
            min-width: 245px;
            -webkit-box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
            box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
        }
    }
}

.menu-logout {
    position: fixed;
    background: #fff;
    z-index: 2;
    top: 64px;
    right: 6%;
    padding: 10px 0 10px 0;
    border: 1px solid;

    .menu {
        display: block;
        cursor: pointer;
        padding: 10px;
        position: relative;
        width: 128px;
        // height: 50px;
        border: none;

        .text-menu {
            margin-left: 30px;
            margin-right: 30px;

            
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}
.hide-menu-logout {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;
}


.logo-bio-medika {
width: 63px;
height: 72px;
margin-left: 32px;
margin-top: 34px;
}

.dashboard-icon{

width: 30px;
height: 30px;
left: 38px;
top: 154px;
}

.dashboard-text{

width: 118px;
height: 22px;
left: 75px;
top: 158px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #FFFFFF;
}

.garisbawah-icon{
    
width: 4px;
height: 69px;
left: 40px;
top: calc(50% - 69px/2 - 310.5px);

background: #4BB965;
border-radius: 49px;
transform: rotate(-90deg);
}

.rectangle-3{
    
width: 199px;
height: 1080px;
left: -6px;
top: 0px;

background: #4BB965;
border-radius: 0px 40px 50px 0px;
}

.rectangle-4{
    
    width: 4px;
    height: 30px;
    left: 189px;
    top: calc(50% - 30px/2 - 350px);
    background: #FFFFFF;
    border-radius: 49px;
}

.people{


width: 23px;
height: 29px;
left: 41px;
top: 205px;

}

.peopletext{

    
    width: 58px;
    height: 22px;
    left: 75px;
    top: 208px;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 17px */
    
    display: flex;
    align-items: center;
    
    color: #0B6E51;
    
    }

    .appointment{
        
width: 26px;
height: 26px;
left: 40px;
top: 257px;

    }

    .appointmenttext{
        
width: 92px;
height: 22px;
left: 75px;
top: 259px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #0B6E51;
    }

    .calendar-icon{
        
width: 26px;
height: 29px;
left: 40px;
top: 310px;

    }


    .calendartext{
        
width: 92px;
height: 22px;
left: 75px;
top: 315px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #0B6E51;

    }


    .calendar-icon2{
        
width: 26px;
height: 29px;
left: 40px;
top: 410px;

    }


    .calendar-text2{
        
width: 92px;
height: 22px;
left: 75px;
top: 415px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #0B6E51;

    }

    .report{
        
width: 26px;
height: 30px;
left: 40px;
top: 366px;
    }

    .conclusion{

        
width: 92px;
height: 22px;
left: 75px;
top: 371px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #0B6E51;

    }

    .frameicon{
    

width: 30px;
height: 30px;
left: 38px;
top: 949px;
    }

    .about{
        

width: 92px;
height: 22px;
left: 75px;
top: 954px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #0B6E51;
    }


    .settingIcon{
        
width: 26px;
height: 26px;
left: 40px;
top: 1008px;

    }

    .setting{
        
width: 92px;
height: 22px;
left: 75px;
top: 1011px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 130%;
/* or 17px */

display: flex;
align-items: center;

color: #0B6E51;
    }


/* The side navigation menu */
.sidebar {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
    
  }
  
  