@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");


.logo-bio-medika-new {
width: 43px;
height: 52px;
margin-left: 32px;
margin-top: 34px;
}

.sidebar-nav-div {
  display: flex;
}


/* Toggle Styles */
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 150px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: rgb(15, 71, 62);
  border-radius: 0px 40px 50px 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 150px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */
.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  #wrapper {
     padding-left: 150px;
   }
 
  #wrapper.toggled {
     padding-left: 0;
   }
 
  #sidebar-wrapper {
     width: 150px;
   }
 
  #wrapper.toggled #sidebar-wrapper {
     width: 0;
   }
 
   #page-content-wrapper {
     padding: 20px;
     position: relative;
   }
 
   #wrapper.toggled #page-content-wrapper {
     position: relative;
     margin-right: 0;
   }

   #sidebarCollapse {
    height: 42px;
    width: 42px;
    position:fixed;
    background: #000;
    border-radius: 30px;
    line-height: 44px;
    text-align: center;
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
    top: 260px;
    transform: rotate(180deg);
    left: -20px;
    transition: all 0.5s ease;
    z-index: 9999;
  }

  #sidebarCollapse svg,#sidebarCollapse img {
    height: 24px;
    width: 24px;
    margin-bottom: 8px;
    margin-right: 10px;
  }
  
  #sidebarCollapse.rotate {
    transform: rotate(180deg);
    /* left: -20px; */
    left: 125px;
  }


 }


@media (min-width: 768px) {
 /* #wrapper {
    padding-left: 250px;
  }

 #wrapper.toggled {
    padding-left: 0;
  }

 #sidebar-wrapper {
    width: 250px;
  }

 #wrapper.toggled #sidebar-wrapper {
    width: 0;
  } */

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }


  
#sidebarCollapse {
  height: 42px;
  width: 42px;
  /* position: absolute; */
  position:fixed;
  background: #000;
  border-radius: 30px;
  line-height: 44px;
  text-align: center;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
  /* top: 100px; */
  top: 260px;
  left: 125px;
  /* right: 100px; */
  transition: all 0.5s ease;
  z-index: 9999;
}
#sidebarCollapse svg,#sidebarCollapse img {
  height: 24px;
  width: 24px;
  margin-bottom: 8px;
  margin-right: 10px;
}
#sidebarCollapse.rotate {
  transform: rotate(180deg);
  left: -20px;
}

}


.separator {
  margin: 2rem 0;
  border-bottom: 1px dashed #d9d9d9;
}
.text-uppercase {
  letter-spacing: 0.1em;
}
.text-gray {
  color: #aaa;
}
.media-body svg,.media-body img {
  height: 100px;
  width: auto;
}
.media-body .media-content {
  color: #fff;
  font-weight: 100;
  font-size: 19px;
  margin: 10px 0;
  letter-spacing: 2px;
}
.media-body .media-sub-content {
  color: #fff;
  font-weight: 100;
  font-size: 13px;
  margin: 0;
}
.vertical-nav {
/*  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);*/
  transition: all 0.4s;
  background-color: rgba(201, 217, 221, 0.8);
}
.vertical-nav .profile {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0 0 20px 20px;
}
.vertical-nav .nav .nav-item {}
.vertical-nav .nav .nav-item .nav-link {
  display: block;
  padding: 0.6rem 1rem;
  color: #000;
  font-weight: normal;
  font-size: 13px;
  background-color: transparent;
  border-left: 5px solid transparent;
  border-radius: 5px 0 0 5px;
}
.vertical-nav .nav .nav-item .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
/*  border-left: 5px solid #000;*/
}
.vertical-nav .nav .nav-item .nav-link img,
.vertical-nav .nav .nav-item .nav-link svg {
  height: 18px;
  width: 18px;
  margin-top: -4px;
}
.vertical-nav .nav .nav-item .nav-link img path,
.vertical-nav .nav .nav-item .nav-link svg path {
  fill: #fff;
}
.vertical-nav .nav .nav-item.active .nav-link {
  background-color: rgba(0, 0, 0, 0.1);
/*  border-left: 5px solid #000;*/
width: 150px;
}
.page-content {
  width: calc(100% - 14rem);
  margin-left: 14rem;
  transition: all 0.4s;
}

#sidebar.active {
  margin-left: -14rem;
}
#content.active {
  width: 100%;
  margin: 0;
}
.mobile {
  display: none;
}
@media (max-width: 768px) {
  #sidebar {
    margin-left: -14rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  .mobile {
    display: block;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 14rem;
    width: calc(100% - 14rem);
  }
}